import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { SEO, Layout, PageHeader, MainWrapper } from "components"
import { MyMaps } from "sections"

const ContactAndMap = styled.div`
  margin: 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

const Map = styled.div`
  padding: 0 30px;

  div {
    border-radius: 20px;
  }
`

const ContactInfo = styled.div`
  padding-top: 50px;

  @media (max-width: 992px) {
    padding-bottom: 50px;
  }

  h1 {
    font-size: 25px;
    font-family: var(--fontSecondary);
    padding-bottom: 30px;
  }

  p {
    font-size: 14px;
    padding-bottom: 10px;
  }

  a {
    color: var(--black);
  }

  > div {
    padding: 5px 0;
  }

  span {
    padding-left: 10px;
    font-size: 13px;
  }
`

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <PageHeader title="Contact Us">
      <StaticImage
        src="../images/slider/5.jpg"
        placeholder="blurred"
        alt="Menu"
        quality="100"
      />
    </PageHeader>
    <MainWrapper>
      <ContactAndMap>
        <ContactInfo>
          <h1 data-sal="slide-up" data-sal-delay="250" data-sal-easing="ease">
            Contact
          </h1>
          <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            For all special events, catering, or other inquiries, please email
            us directly at
          </p>

          <div data-sal="slide-up" data-sal-delay="350" data-sal-easing="ease">
            <StaticImage
              src="../images/mail.png"
              alt="Place"
              quality="100"
              height={20}
              placeholder="blurred"
            />
            <a href="mailto:admin@spruzzopalisades.com">
              {" "}
              <span
                data-sal="slide-up"
                data-sal-delay="350"
                data-sal-easing="ease"
              >
                admin@spruzzopalisades.com
              </span>
            </a>
          </div>
          <div data-sal="slide-up" data-sal-delay="350" data-sal-easing="ease">
            <StaticImage
              src="../images/phone.png"
              alt="Place"
              quality="100"
              height={18}
              placeholder="blurred"
            />
            <a href="tel: (424) 426-5900">
              <span
                data-sal="slide-up"
                data-sal-delay="350"
                data-sal-easing="ease"
              >
                {"  "}
                (424) 426-5900
              </span>
            </a>
          </div>
          <div data-sal="slide-up" data-sal-delay="350" data-sal-easing="ease">
            <StaticImage
              src="../images/place.png"
              alt="Place"
              quality="100"
              placeholder="blurred"
              height={20}
            />

            <span
              data-sal="slide-up"
              data-sal-delay="350"
              data-sal-easing="ease"
            >
              538 Palisades Dr, Los Angeles, CA 90272
            </span>
          </div>
        </ContactInfo>
        <Map data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
          <MyMaps />
        </Map>
      </ContactAndMap>
    </MainWrapper>
  </Layout>
)

export default ContactPage
